<template>
  <div class="footer-top">
    <client-only>
      <div class="footer-logo">
        <LBLogo open />
        <p class="paragraph-2 logo-text">
          Designed for the real lives of women
        </p>
      </div>
      <MSocialLinks class="m-social-links" />
    </client-only>
  </div>
</template>

<script>
import isOnPage from 'common/mixins/isOnPage';
import ClientOnly from 'vue-client-only';
import MSocialLinks from 'common/components/molecules/footer/m-social-links'
import LBLogo from 'common/components/atoms/a-link-logo';

export default {
  name: 'MFooterLbSection',
  components: {
    ClientOnly,
    MSocialLinks,
    LBLogo
  },
  mixins: [isOnPage]
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.footer-top {
  padding: 40px 80px;
  border-bottom: 1px solid var(--neutral-100);
  display: flex;
  justify-content: space-between;
  @include for-mobile {
    flex-wrap: wrap;
    padding: 32px 20px;
    justify-content: center;
    .m-social-links {
      margin-top: 32px;
    }
  }
  .footer-logo {
    .logo-text {
      margin-top: var(--spacing-lg, 12px);
    }
    ::v-deep .logo {
      width: 259px;
      height: 32px;
    }
  }
}
</style>
