<template>
  <div>
    <div class="social-links">
      <a v-for="item in storewiseSocialHandleData[currentStoreView]" :key="item.icon" :href="item.href" target="__blank">
        <svg class="lb-icon no-fill stroke icon">
          <use :xlink:href="`#${item.icon}`" />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { storewiseSocialHandleData } from 'common/config/footer/social-links.js';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'MSocialLinks',
  data () {
    return {
      currentStoreView: currentStoreView().storeCode,
      storewiseSocialHandleData
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.social-links {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  gap: 32px;
  flex-basis: 100%;
  .icon {
    width: 28px;
    height: 28px;
    @media screen and (min-width: $desktop-min) and (max-width: $desktop-min){
      width: 24px;
      height: 24px;
    }
  }
}
</style>
