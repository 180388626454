const commonSocialHandleData = {
  'facebook': {
    name: 'Facebook',
    icon: 'logo_social-fb'
  },
  'instagram': {
    name: 'Instagram',
    icon: 'logo_social-insta'
  },
  'spotify': {
    name: 'Spotify',
    icon: 'logo_social-spotify'
  },
  'tikTok': {
    name: 'TikTok',
    icon: 'logo_social-tiktok'
  },
  'youTube': {
    name: 'YouTube',
    icon: 'logo_social-youtube'
  }
}
export const storewiseSocialHandleData = {
  'intl': [
    {
      name: commonSocialHandleData.facebook.name,
      icon: commonSocialHandleData.facebook.icon,
      href: 'https://www.facebook.com/lovebonito/'
    },
    {
      name: commonSocialHandleData.instagram.name,
      icon: commonSocialHandleData.instagram.icon,
      href: 'https://www.instagram.com/lovebonito/'
    },
    {
      name: commonSocialHandleData.spotify.name,
      icon: commonSocialHandleData.spotify.icon,
      href: 'https://open.spotify.com/user/lovebonitomusic'
    },
    {
      name: commonSocialHandleData.tikTok.name,
      icon: commonSocialHandleData.tikTok.icon,
      href: 'https://www.tiktok.com/@lovebonito'
    },
    {
      name: commonSocialHandleData.youTube.name,
      icon: commonSocialHandleData.youTube.icon,
      href: 'https://www.youtube.com/channel/UClFWjOkFAPFf_tEW27Yom8A'
    }
  ],
  'hk': [
    {
      name: commonSocialHandleData.facebook.name,
      icon: commonSocialHandleData.facebook.icon,
      href: 'https://www.facebook.com/lovebonito/'
    },
    {
      name: commonSocialHandleData.instagram.name,
      icon: commonSocialHandleData.instagram.icon,
      href: 'https://www.instagram.com/lovebonito/'
    },
    {
      name: commonSocialHandleData.spotify.name,
      icon: commonSocialHandleData.spotify.icon,
      href: 'https://open.spotify.com/user/lovebonitomusic'
    },
    {
      name: commonSocialHandleData.tikTok.name,
      icon: commonSocialHandleData.tikTok.icon,
      href: 'https://www.tiktok.com/@lovebonito'
    },
    {
      name: commonSocialHandleData.youTube.name,
      icon: commonSocialHandleData.youTube.icon,
      href: 'https://www.youtube.com/channel/UClFWjOkFAPFf_tEW27Yom8A'
    }
  ],
  'jp': [
    {
      name: commonSocialHandleData.facebook.name,
      icon: commonSocialHandleData.facebook.icon,
      href: 'https://www.facebook.com/lovebonito.jp'
    },
    {
      name: commonSocialHandleData.instagram.name,
      icon: commonSocialHandleData.instagram.icon,
      href: 'https://www.instagram.com/lovebonitojp'
    },
    {
      name: commonSocialHandleData.spotify.name,
      icon: commonSocialHandleData.spotify.icon,
      href: 'https://open.spotify.com/user/lovebonitomusic'
    },
    {
      name: commonSocialHandleData.tikTok.name,
      icon: commonSocialHandleData.tikTok.icon,
      href: 'https://www.tiktok.com/@lovebonito'
    },
    {
      name: commonSocialHandleData.youTube.name,
      icon: commonSocialHandleData.youTube.icon,
      href: 'https://www.youtube.com/channel/UClFWjOkFAPFf_tEW27Yom8A'
    }
  ],
  'my': [
    {
      name: commonSocialHandleData.facebook.name,
      icon: commonSocialHandleData.facebook.icon,
      href: 'https://www.facebook.com/lovebonito'
    },
    {
      name: commonSocialHandleData.instagram.name,
      icon: commonSocialHandleData.instagram.icon,
      href: 'https://www.instagram.com/lovebonito_my/'
    },
    {
      name: commonSocialHandleData.spotify.name,
      icon: commonSocialHandleData.spotify.icon,
      href: 'https://open.spotify.com/user/lovebonitomusic'
    },
    {
      name: commonSocialHandleData.tikTok.name,
      icon: commonSocialHandleData.tikTok.icon,
      href: 'https://www.tiktok.com/@lovebonito'
    },
    {
      name: commonSocialHandleData.youTube.name,
      icon: commonSocialHandleData.youTube.icon,
      href: 'https://www.youtube.com/channel/UClFWjOkFAPFf_tEW27Yom8A'
    }
  ],
  'sg': [
    {
      name: commonSocialHandleData.facebook.name,
      icon: commonSocialHandleData.facebook.icon,
      href: 'https://www.facebook.com/lovebonito'
    },
    {
      name: commonSocialHandleData.instagram.name,
      icon: commonSocialHandleData.instagram.icon,
      href: 'https://www.instagram.com/lovebonito'
    },
    {
      name: commonSocialHandleData.spotify.name,
      icon: commonSocialHandleData.spotify.icon,
      href: 'https://open.spotify.com/user/lovebonitomusic'
    },
    {
      name: commonSocialHandleData.tikTok.name,
      icon: commonSocialHandleData.tikTok.icon,
      href: 'https://www.tiktok.com/@lovebonito'
    },
    {
      name: commonSocialHandleData.youTube.name,
      icon: commonSocialHandleData.youTube.icon,
      href: 'https://www.youtube.com/channel/UClFWjOkFAPFf_tEW27Yom8A'
    }
  ],
  'us': [
    {
      name: commonSocialHandleData.facebook.name,
      icon: commonSocialHandleData.facebook.icon,
      href: 'https://www.facebook.com/lovebonito'
    },
    {
      name: commonSocialHandleData.instagram.name,
      icon: commonSocialHandleData.instagram.icon,
      href: 'https://www.instagram.com/lovebonitousa'
    },
    {
      name: commonSocialHandleData.spotify.name,
      icon: commonSocialHandleData.spotify.icon,
      href: 'https://open.spotify.com/user/lovebonitomusic'
    },
    {
      name: commonSocialHandleData.tikTok.name,
      icon: commonSocialHandleData.tikTok.icon,
      href: 'https://www.tiktok.com/@lovebonitousa'
    },
    {
      name: commonSocialHandleData.youTube.name,
      icon: commonSocialHandleData.youTube.icon,
      href: 'https://www.youtube.com/@LoveBonitoGlobal'
    }
  ]
}
